import axios from 'axios';

/**
 * Victoria plum Checkout
 */
export default {
    /**
     * @returns {Promise}
     */
    fromCart() {
        return axios.post('/order/from-cart');
    },

    /**
     * Ask if the current order being processed still requires payment
     * useful for polling when 3d secure hangs
     *
     * @returns {Promise}
     */
    requiresPayment() {
        return axios.get('/order/requires-payment');
    },
};

import cart from '~@api/cart';
import order from '~@api/order';
import CustomEventHelper from '~@helpers/custom-event';
import basketTracking from '~@tracking/basket';
import addtoCartTracking from '~@tracking/enhanced-ecommerce/add-to-cart';
import enhancedEcommerceTracking from '~@tracking/enhanced-ecommerce/remove-from-cart';

/**
 * Get the error message to display to the client
 *
 * @param {object} error
 * @returns {string}
 */
function serverErrorMessage(error) {
    const defaultMessage = 'There was an error updating your items, please refresh your page';
    try {
        return error.response.data.error // Handle non 200 response error
            || error.response.data.message // Handle exceptions
            || defaultMessage;
    } catch (e) {
        return defaultMessage;
    }
}

/**
 * @param {Array} cartItems
 * @returns {*}
 */
function cartQuantity(cartItems) {
    return cartItems.reduce((qty, item) => qty + item.quantity, 0);
}

/**
 * Dispatch an event to the window element.
 *
 * @param {Array} cartItems
 */
function emitUpdate(cartItems) {
    const event = CustomEventHelper('cartupdated', { itemCount: cartQuantity(cartItems) });
    window.dispatchEvent(event);
}

export default {
    state: {
        recommendedItems: [],
        items: [],
        cartPromotedProduct: null,
        subtotals: {
            subtotal: {},
            discounts: {},
            delivery: {},
            tax: null,
        },
        totals: {
            total: {},
            saving: {},
        },
        paymentOptions: {},
        usps: {},
        finance: {
            isEligible: false,
            qualifies: false,
            isBelowMaxSpend: false,
            options: [],
            longestTermOption: {},
            spendToQualify: null,
            minimumSpend: null,
            maximumSpend: null,
        },
    },
    getters: {
        getSubtotals: state => state.subtotals,
        getTotals: state => state.totals,
        getItems: state => state.items,
        getFinance: state => state.finance,
        getRecommendedItems: state => state.recommendedItems,
        getPaymentOptions: state => state.paymentOptions,
        getUsps: state => state.usps,
        getIsRepaymentsAvailable: state => !!state.finance.options,
        cartHasPromotedProduct: state => state.cartPromotedProduct && Object.keys(state.cartPromotedProduct).length > 0,
    },
    mutations: {
        refreshCart(state, data) {
            state.subtotals = data.subtotals;
            state.totals = data.totals;
            state.recommendedItems = data.recommendedItems;
            state.items = data.items;
            state.paymentOptions = data.paymentOptions;
            state.usps = data.usps;
            state.finance = data.finance;
            state.cartPromotedProduct = data.cartPromotedProduct;
            emitUpdate(data.items);
        },
    },
    actions: {
        fetchCart({ commit }) {
            return new Promise((done, fail) => {
                const api = window.isQuote ? order : cart;

                api.fetchAll()
                    .then((response) => {
                        commit('refreshCart', response.data);
                        done();
                    }).catch((error) => {
                        fail(serverErrorMessage(error));
                    });
            });
        },
        addCartItem({ commit }, { csi, quantity, location = 'cart' }) {
            return new Promise((done, fail) => {
                cart.add(csi, quantity)
                    .then((response) => {
                        window.dispatchEvent(new CustomEventHelper('item-added-to-basket', {
                            detail: {
                                csi,
                                quantity,
                            },
                        }));
                        basketTracking.updateBasket(response.data);
                        addtoCartTracking.addToCart(response.data, location);
                        return cart.fetchAll();
                    }).then((response) => {
                        commit('refreshCart', response.data);
                        done();
                    }).catch((error) => {
                        fail(serverErrorMessage(error));
                    });
            });
        },
        updateCartItem({ commit }, { csi, quantity }) {
            return new Promise((done, fail) => {
                cart.update(csi, quantity)
                    .then((response) => {
                        basketTracking.updateBasket(response.data);
                        return cart.fetchAll();
                    }).then((response) => {
                        commit('refreshCart', response.data);
                        window.dispatchEvent(new CustomEventHelper('item-updated-in-basket', response.data));
                        done();
                    }).catch((error) => {
                        fail(serverErrorMessage(error));
                    });
            });
        },
        removeCartItem({ dispatch, commit }, { csi = '', location = '' }) {
            return new Promise((done, fail) => {
                cart.remove(csi)
                    .then((response) => {
                        basketTracking.updateBasket(response.data);
                        enhancedEcommerceTracking.removeFromCart(response.data, location);
                        return cart.fetchAll();
                    })
                    .then((response) => {
                        commit('refreshCart', response.data);
                        window.dispatchEvent(new CustomEventHelper('item-removed-from-basket', response.data));
                        done();
                    }).catch((error) => {
                        // Catch race condition leaving an already removed item rendered
                        if (error.response.status === 410) {
                            dispatch('fetchCart');
                            return;
                        }

                        fail(serverErrorMessage(error));
                    });
            });
        },
        setCartData({ commit }, data) {
            commit('refreshCart', data);
        },
    },
};

import dl from '~@tracking/data-layer';

// https://developers.google.com/tag-manager/enhanced-ecommerce#cart

export default {
    /**
     * @param {object} response
     * @param {string} location
     */
    removeFromCart(response, location) {
        const { removedItem: product } = response;
        dl.push({
            event: 'removeFromCart',
            ecommerce: {
                remove: {
                    products: [{
                        name: product.name,
                        id: product.sku_code,
                        price: product.unit_price,
                        brand: product.brand,
                        category: product.category,
                        variant: product.id,
                        quantity: product.quantity,
                    }],
                },
            },
        });

        dl.push({
            event: 'productRemove',
            ecommerce: {
                remove: {
                    products: [{
                        id: product.sku_code,
                        name: product.name,
                        brand: product.brand,
                        variant: product.id,
                        category: product.category,
                    }],
                },
            },
        });

        const { ecommerce } = response;
        ecommerce.items.forEach((item) => { item.item_list_name = location; });

        // The cart data may contain parameters we don't need for this event
        // The 55 audit recommended we remove them
        delete ecommerce.shipping_tier;
        delete ecommerce.payment_type;

        dl.addEcommerceEvent({
            event: 'remove_from_cart',
            ecommerce,
        });
    },
};

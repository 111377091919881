import Vue from 'vue';
import Vuex from 'vuex';
import '~@bundles/shared';
import store from '~@store/cart';

const mountPoint = document.querySelector('.i-mount-mini-cart');
const miniCartSelector = document.querySelector('.i-open-mini-cart');

let hasMounted = false;

if (miniCartSelector && mountPoint) {
    miniCartSelector.addEventListener('click', mountMiniCart);
}

window.addEventListener('mount-minicart', mountMiniCart);

export default async function mountMiniCart() {
    if (hasMounted) {
        return;
    }

    try {
        Vue.use(Vuex);

        new Vue({
            el: mountPoint,
            components: {
                miniCart: () => import(
                    /* webpackChunkName: "mini-cart-dynamic" */
                    '../../../vue/component/mini-cart/MiniCart'
                ),
            },
            store: new Vuex.Store(store),
            inject: [],
        });

        hasMounted = true;
    } catch (e) {
        // Ignore
    }
}
